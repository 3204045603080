import { Children, useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import { IoClose } from "react-icons/io5";
import * as Yup from 'yup'
import Button from '../components/Button'
import { fetchPostContact } from '../redux/slices/post-contact.slice';
import { useSelector, useDispatch } from 'react-redux';
import ContactImage from '../assets/images/contactImage.png'
import { toast } from 'react-toastify';
import ThanksPopup from './thank-you-popup';
import metaIcon from '../assets/images/metaIcon.png'

const CenterPopup = (props) => {

    const { show, setShow, children } = props
    const [showThanksPopup, setShowThanksPopup] = useState(false)

    const dispatch = useDispatch()



    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            company: '',
            Referral: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            // phone: Yup.string().required('Required'),
            subject: Yup.string().required('Required'),
            message: Yup.string().required('Required'),
            // company: Yup.string().required('Required'),
            Referral: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            setShow(false)

            // dispatch(fetchPostContact(values)).then((response) => {
            //     if (response?.payload.success) {
            //         setShowThanksPopup(true)
            //         formik.resetForm()
            //     }
            // }
            // )
        }
    })








    const numberValidate = (event) => {
        const { name, value } = event.target;
        const numericValue = value.replace(/\D/g, '').slice(0, 20);
        formik.setFieldValue(name, numericValue);
    }



    const handleClose = () => {
        setShow(false);
        formik.resetForm();
    }

    return (

        <div>
            <Modal
                backdrop="static"
                keyboard={false}
                onHide={setShow}
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body
                    className='p-1' >

                    <div className='md:w-[100%] items-center  flex md:flex-row flex-col rounded '>

                        <div className='md:w-[65%] w-[100%] px-[25px]  bg-[#F7F8FA]'>
                            <div className='flex items-center justify-between'> <p className='my-[20px] text-[24px] font-semibold'>Contact Us</p><IoClose className='md:hidden cursor-pointer' size={25} onClick={handleClose} /></div>

                            <form action="" onSubmit={formik.handleSubmit} className='w-[100%] flex flex-col gap-[10px]'>

                                <div>
                                    <p className='m-0'>Name</p>
                                    <input onBlur={formik.handleBlur} className={`${formik.touched.name && formik.errors.name ? 'border-red-500 border-1' : 'border'} mt-1 outline-none px-2 rounded w-[100%] h-[40px]`} type="text" name="name" onChange={formik.handleChange} value={formik.values.name} />

                                </div>


                                <div className='flex items-center justify-between'>

                                    <div className='w-[48%]'>
                                        <p className='m-0'>Email</p>
                                        <input onBlur={formik.handleBlur} className={` px-2 rounded mt-1 w-[100%] h-[40px] ${formik.touched.email && formik.errors.email ? 'border-red-500 border-1' : 'border'} `} type="email" name="email" onChange={formik.handleChange} value={formik.values.email} />
                                    </div>
                                    <div className='w-[48%]'>
                                        <p className='m-0'>Phone.no <span className='text-[#888888] md:text-[14px] text-[10px]'>(Optional)</span></p>
                                        <input className={`${formik.touched.phone && formik.errors.phone ? 'border-red-500 border-b' : 'border'} px-2 rounded w-[100%] mt-1 h-[40px]`} type="text" name="phone"
                                            onChange={(e) => numberValidate(e)} value={formik.values.phone} />
                                    </div>
                                </div>

                                <div className='flex  flex-col'>

                                    <p className='m-0'>Message</p>
                                    <input type="text" placeholder='Subject' className={`${formik.touched.subject && formik.errors.subject ? 'border-red-500 border-1' : 'border'} mt-1 mb-2 px-2 rounded w-[100%] h-[40px]`} name="subject" onChange={formik.handleChange} value={formik.values.subject} />
                                    <textarea className={`mt-1 ${formik.touched.message && formik.errors.message ? 'border-red-500 border-b' : 'border'} p-2 rounded w-[100%] resize-none`} cols={30} rows={3} name="message" placeholder='Type your Message...' id="" onChange={formik.handleChange} value={formik.values.message}></textarea>
                                </div>



                                <div className='flex items-center justify-between mb-[20px]'>
                                    <div className='w-[48%]'>
                                        <p className='m-0'>Company name <span className='text-[#888888] hidden md:inline md:text-[14px] text-[10px]'>(Optional)</span></p>
                                        <input type="text" className='border mt-1 px-2 rounded w-[100%] h-[40px]' name="company"
                                            onChange={formik.handleChange} value={formik.values.company} />

                                    </div>
                                    <div className='w-[48%] '>

                                        <p className="m-0" >Referral Source  </p>
                                        <select name="Referral" className={`mt-1 ${formik.touched.Referral && formik.errors.Referral ? 'border-red-500 border-1' : 'border'} px-2 rounded w-[100%] h-[40px]`}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.Referral}
                                            id="">

                                            <option className='text-gray-400' value="">Select</option>
                                            <option value="Google">Google</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Linkedin">Linkedin</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>


                                <div className='text-end mb-[20px]' >
                                    <button type='submit'>
                                        <Button btnName="Submit" btnStyle="cursor-pointer bg-[#051F54] py-[10px] px-[30px] rounded-[8px] text-[14px] text-white" />
                                    </button>
                                </div>

                            </form>




                        </div>


                        <div className='w-[35%] hidden bg-white gap-[50px] md:flex flex-col   '>

                            <div className='flex justify-end pe-2'>
                                <IoClose className='cursor-pointer text-gray-600' size={40} onClick={handleClose} />
                            </div>

                            <img src={ContactImage} alt="pic" />

                            <div className='h-[40px]'>
                            </div>
                        </div>




                    </div>





                </Modal.Body>

            </Modal>




            <div>
                <ThanksPopup

                    showThanksPopup={showThanksPopup} setShowThanksPopup={setShowThanksPopup}
                >
                    <div className='bg-white flex items-center flex-col py-[30px] '>

                        <img src={metaIcon} alt="..." />

                        <p className='text-purple-700 m-0 font-bold text-[80px]'> Thank you!</p>
                        <div className="md:w-[20%] my-3 h-[6px] bg-orange-500 rounded-full"></div>

                        <p className='text-purple-700  mb-0 text-[20px] font-medium ' >Your message has been received.</p>
                        <p className='text-purple-700 mb-0 text-[20px] font-medium ' >You will receive an email for confirmation.</p>



                        <div className="mt-[30px]" onClick={() => setShowThanksPopup(false)}>
                            <Button btnName={"Close"} btnStyle="cursor-pointer bg-orange-500 py-[10px] px-[38px] rounded-[8px] text-[18px] text-white" />
                        </div>

                    </div>
                </ThanksPopup>
            </div>
        </div>




    );
}

export default CenterPopup

