import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from '../components/Button'
import metaIcon from '../assets/images/metaIcon.png'


const ThanksPopup = (props) => {
    const { showThanksPopup, setShowThanksPopup, children } = props

    return (
        <div>
            <Modal
                backdrop="true"
                // keyboard={false}
                onHide={setShowThanksPopup}
                backdropClassName='model-bg'
                show={showThanksPopup}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >



                <Modal.Body
                    className='p-1shadow' >

                    {children}

                </Modal.Body>

            </Modal>
        </div >
    )
}

export default ThanksPopup
