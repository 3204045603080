export const data = [
    {
        ques: "Is there a free trial available?",
        ans: "Yes, you can try us for free for 30 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."

    },
    {
        ques: "Can I change my plan later?",
        ans: ""

    },
    {
        ques: "What is your cancellation policy?",
        ans: ""

    },
    {
        ques: "Can other info be added to an invoice?",
        ans: ""

    },
    {
        ques: "How does billing work?",
        ans: ""

    },
    {
        ques: "How do I change my account email?",
        ans: ""

    }

]