import { useState, useEffect, React } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../components/Button'

import { PopupButton, PopupModal, PopupWidget, InlineWidget } from "react-calendly";
function DemoPopup(props) {


    const { show, setShow } = props



    return (
        <>






            {/* 
            <Modal
                show={show}
                onHide={setShow}
                backdrop="true"
                // dialogClassName='bg-red-100'
                size='xl'
                keyboard={true}
            >

                <Modal.Body className=' bg-[#a8b3c9] rounded'>
                    <div className='flex flex-col md:my-[30px] md:flex-row gap-[50px] md:gap-0 items-center'>
                        <div className='md:w-[70%] md:px-[100px]'>
                            <p className='mb-[20px] text-[46px] font-bold
                            leading-[50px] text-white'>Start Growing your
                                Business.</p>
                            <p className='m-0'>With LeadSynQ. It’s simple, personal & productive for everyone.</p>
                        </div>

                        <div className='md:w-[30%] flex items-center flex-col gap-2' >
                            <Button btnName="Start your 15-day free Trial" btnStyle="cursor-pointer bg-[#0F337C] py-[12px] px-[16px] rounded-[8px] text-[14px] text-white" />
                            <p className='m-0 text-white text-[14px]' >No Credit card required. Cancel anytime.</p>
                        </div>
                    </div>
                </Modal.Body>

            </Modal> */}
        </>
    );
}

export default DemoPopup;