import React from 'react'

const Button = (props) => {

    const { btnName, btnStyle } = props

    return (

        <div >
            <div style={{ boxShadow: "#050E210A 0px 4px 8px" }} className={`${btnStyle} w-fit font-medium`}>{btnName}</div>
        </div>
    )
}

export default Button
