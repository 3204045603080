import React from 'react'
import MailImage from '../assets/images/mailImage.png'

const MailRecording = () => {
    return (
        <div className='h-[90vh] w-full md:m-0 mt-[100px] flex md:flex-row flex-col container items-center'>
            <div className='md:w-[50%] w-[100%] px-[20px] md:px-[50px]'>
                <p className='m-0 font-semibold text-[#DEA209]'>Efficiency</p>
                <p className='mb-[24px] leading-[55px] font-semibold text-[40px]' >Mail Recording
                    Track Interactions </p>
                <p className='m-0 text-[20px]'>{"Record emails related to leads and deals for a comprehensive view of all interactions."}</p>
            </div>

            <div className='md:w-[50%] w-[100%]'>
                <img src={MailImage} alt="" />
            </div>
        </div>
    )
}

export default MailRecording
