

const config = {
    production: {
        url: "",
        apiUrl: "https://c4j1qf1ct3.execute-api.ap-south-1.amazonaws.com/prod/",
    },
    develop: {
        url: "",
        apiUrl: "https://rsdxtqspm6.execute-api.ap-southeast-1.amazonaws.com/dev/",
    },
    local: {
        url: "http://localhost:5000/",
        apiUrl: "http://localhost:3000/dev/",
    },
};
export const environment = "production";
const hostConfig = {
    WEB_URL: config[environment].url,
    IMAGE_URL: `https://${config[environment].bucketName}.s3.ap-southeast-1.amazonaws.com`,
    API_URL: config[environment].apiUrl,
    S3_BUCKET: `${config[environment].bucketName}`,
};

export { hostConfig };