import React from 'react'
import Icon1 from '../assets/icons/Icon1.png'
import Icon2 from '../assets/icons/Icon2.png'
import Icon3 from '../assets/icons/Icon3.png'
import Icon4 from '../assets/icons/Icon4.png'
import Icon5 from '../assets/icons/Icon5.png'
import Icon6 from '../assets/icons/Icon6.png'
import Card from '../components/card'

import SolutionImage from '../assets/images/solutionImage.png'

const TrackingSolution = () => {

    const data = [
        {
            icon: Icon1,
            title: 'Efficiency',
            caption: 'Effortless Lead Capture',
            description: 'Automatically gather high-quality leads with ease, ensuring you never miss an opportunity.'
        },
        {
            icon: Icon2,
            title: 'Efficiency',
            caption: 'Precision Lead Tracking',
            description: "Keep a close eye on every lead's journey, from initial contact to conversion, with pinpoint accuracy."
        },
        {
            icon: Icon3,
            title: 'Efficiency',
            caption: 'Lead Nurturing',
            description: 'Build and maintain meaningful relationships with your leads to maximize conversion rates.'
        },
        {
            icon: Icon4,
            title: 'Efficiency',
            caption: 'Analytics & Reporting',
            description: 'Leverage powerful analytics to gain actionable insights and make data-driven decisions.'
        },
        {
            icon: Icon5,
            title: 'Efficiency',
            caption: 'Optimized Sales Funnel',
            description: 'Refine and enhance every step of your sales funnel to drive better performance and results.'
        },
        {
            icon: Icon6,
            title: 'Accountability',
            caption: 'Smart Lead Scoring',
            description: 'Identify and prioritize the most promising leads to streamline your follow-up process.'
        }
    ]





    return (
        <div className='relative '>

            <div id='solution' className=' container  mt-[120px] md:mt-[10px]'>
                <div className='flex items-center flex-col justify-center'>
                    <p className='mb-[16px] text-[#FB761C] text-[40px] font-semibold'>Our lead-tracking Solutions</p>
                    <p className='m-0 text-[22px] font-medium'> Experience a seamless lead management journey with our all-in-one solution:</p>
                </div>

                <div className='grid  z-[100] md:grid-cols-3 gap-4 grid-cols-1 my-[57px] '>

                    {data.map((item, index) => (
                        <div>
                            <Card key={index} icon={item?.icon} title={item?.title} caption={item?.caption} description={item?.description} />
                        </div>
                    ))}

                </div>


            </div>

            <div style={{ backgroundImage: `url(${SolutionImage})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }} className='absolute w-[100%] md:w-[50%] h-[100vh] z-[-1] top-0'>

            </div>
        </div>
    )
}

export default TrackingSolution
