
import { hostConfig } from "../config/index";
import { URL_CONSTANTS } from "../api/urls";



export const postDataApi = (requestUrl, params, service) => {
    return fetch(
        `${hostConfig.API_URL}${requestUrl}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
    ).then((response) => {
        if (!response.ok) {

            return response.json();
        }

        return response.json();

    }).catch((error) => {
        console.log(error);
    });
}

export const getDataApi = (requestUrl, params, service) => {
    return fetch(
        `${hostConfig.API_URL}${requestUrl}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
    ).then((response) => {
        if (!response.ok) {
            return response.json();
        }

        return response.json();

    }).catch((error) => {
        console.log(error);
    });
}