import React from 'react'
import PerformanceImage from '../assets/images/performanceImage.png'

const Performance = () => {
    return (
        <div className='h-[90vh] w-[100%] md:m-0 flex md:flex-row flex-col container items-center'>
            <div className='md:w-[50%] w-[100%] px-[20px] '>
                <p className='m-0 font-semibold text-[#047AF8]'>Efficiency</p>
                <p className='mb-[24px] leading-[55px] font-semibold text-[40px]' >Comparison (Performance)</p>
                <p className='m-0 text-[20px]'>{"Benchmark performance, analyze trends, and compare metrics visually to gain actionable insights."}</p>
            </div>

            <div className='md:w-[50%] w-[100%]'>
                <img src={PerformanceImage} alt="" />
            </div>
        </div>
    )
}

export default Performance
