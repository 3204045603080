import React from 'react'
import BannerSection from './banner-section'
import BackgroundRectangle from './background-rectangle'
import BgGridImage from '../assets/images/bg_grid.png'
import TrackingSolution from './our-tracking-solution'
import ImportExport from './import-export'
import RolesAssignment from './roles-assignment'
import MailRecording from './mail-recording'
import TrackingUpdates from './tracking-updates'
import KPIDashboard from './kpi-dashboard'
import KanbanBoard from './kanban-board'
import Performance from './performance'
import Reasons from './reasons'
// import SubscriptionSection from './subscription-section'
import FAQ from "./faq-section"
import DemoBanner from './demo-banner'


const Main = () => {
    return (
        <div className='' >
            <BannerSection />
            <BackgroundRectangle />
            <TrackingSolution />
            <ImportExport />
            <RolesAssignment />
            <MailRecording />
            <TrackingUpdates />
            <KPIDashboard />
            <KanbanBoard />
            <Performance />
            <Reasons />
            {/* <SubscriptionSection /> */}
            <FAQ />
            <DemoBanner />
        </div>
    )
}

export default Main