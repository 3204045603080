import React from 'react'
import ReasonImage from '../assets/images/reasonImage.png'
import TickIcon from '../assets/icons/tickIcon.png'

const Reasons = () => {
    return (
        <div className='mt-[100px]'>


            <div className='w-[100%] absolute z-10 py-[50px]'>

                <div className='flex md:flex-row flex-col md:gap-0 gap-[20px] items-center container'>

                    <div className='flex flex-col md:p-[20px] w-[100%] md:w-[50%]'>

                        <p className='mb-[50px] text-[30px] font-semibold'>3 Reasons to consider <span className='text-[#0F337C]'>LeadSynQ</span></p>

                        <div className='flex flex-col gap-[30px]'>

                            <div className='flex items-start gap-2'>
                                <img width={20} src={TickIcon} alt="..." />
                                <div>
                                    <p className='mb-[8px]  font-semibold'>Not just another Lead Tracker</p>
                                    <p className='m-0 text-[#354667] text-[14px]'>
                                        {"Comprehensive Lead Management Platform - Capture, Track, and Nurture Leads + Automated Follow-Ups + In-depth Analytics."}
                                    </p>
                                </div>
                            </div>
                            <div className='flex items-start gap-2'>
                                <img width={20} src={TickIcon} alt="..." />
                                <div>
                                    <p className='mb-[8px]  font-semibold'>Achieve Exceptional Growth</p>
                                    <p className='m-0 text-[#354667] text-[14px]'>
                                        {"Dedicated Success Team to guide, customize, and implement the Lead Tracking system tailored to your business needs."}
                                    </p>
                                </div>
                            </div>
                            <div className='flex items-start gap-2'>
                                <img width={20} src={TickIcon} alt="..." />
                                <div>
                                    <p className='mb-[8px]  font-semibold'>Enhanced Efficiency and Productivity</p>
                                    <p className='m-0 text-[#354667] text-[14px]'>
                                        {"Streamlined processes and tools to optimize lead management, ensuring no opportunity is missed.."}
                                    </p>
                                </div>
                            </div>


                        </div>

                    </div>






                    <div className='md:w-[50%] md:block hidden w-[100%]'>
                        <img src={ReasonImage} alt="import_img" />
                    </div>
                </div>
            </div>

            <div className='bg-sky-100  opacity-40 h-[70vh] md:h-[100vh]  '>

            </div>
        </div>
    )
}

export default Reasons
