import React, { useState, useEffect } from 'react'
import Button from '../components/Button'
import Image1 from '../assets/images/image1.png'
import DemoPopup from '../components/demo-popup'
import { fetchGetSubscriptions } from '../redux/slices/get-subscription.slice'
import { useSelector, useDispatch } from 'react-redux'
import { InlineWidget, } from "react-calendly";
import ThanksPopup from '../components/thank-you-popup'
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoClose } from 'react-icons/io5'
const BannerSection = () => {

    // const [show, setShow] = useState(false)

    const dispatch = useDispatch()
    const [showThanksPopup, setShowThanksPopup] = useState(false);

    useEffect(() => {
        dispatch(fetchGetSubscriptions())
    }, [dispatch])


    const openCalendly = () => {
        setShowThanksPopup(true);
    };

    // const handleGetDemo = () => {
    //     window.Calendly.initPopupWidget({ url: "https://calendly.com/alq-karthik" })
    //     return false
    // }

    return (


        <div className='absolute md:w-[98.5vw] z-10'>

            <div className='container md:h-[95vh] md:flex-row w-[100%] flex-col flex items-center justify-center'>
                <div className='md:w-[50%] 2-[100%] flex flex-col gap-[24px]'>
                    <p className='m-0 font-bold text-[46px]'>Elevate Your Customer Relationships with Our <span className='text-[#047af8]'>CRM Solution</span></p>

                    <p className='m-0 md:w-[75%] w-[100%] font-normal text-[16px] leading-6 text-[#000000]'>
                        {"What is lead tracking? It's acquiring and managing prospects at every touch point on their journey to becoming customers. With our advanced lead-tracking system, every step becomes more efficient and effective"} </p>
                    <div onClick={openCalendly}>
                        <Button btnName="Get free demo" btnStyle="cursor-pointer bg-[#0F337C] py-[12px] px-[16px] rounded-[8px] text-[14px] text-white" />
                    </div>
                </div>
                <div className='md:w-[50%] w-full h-[100%] flex items-center'>
                    <img width={"100%"} src={Image1} alt="..." />
                </div>

            </div>


            {/* <DemoPopup show={show} setShow={setShow} /> */}

            <div className=''>
            </div>
            <ThanksPopup showThanksPopup={showThanksPopup} setShowThanksPopup={setShowThanksPopup}>
                <div>
                    <div className='w-[100%]  flex justify-end '>

                        <IoClose size={30} className='cursor-pointer mb-[20px]' onClick={() => setShowThanksPopup(false)} />

                    </div>
                    <InlineWidget
                        url="https://calendly.com/alq-karthik" />
                </div>
            </ThanksPopup>


        </div>

    )
}

export default BannerSection
