import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../header/header'
import Footer from '../footer/footer'
import BgGridImage from '../../assets/images/bg_grid.png'

const Layout = () => {
    return (
        <div className=''>

            <Header />
            <div className='md:w-[98.9vw]' style={{ backgroundImage: `url(${BgGridImage})`, backgroundSize: 'cover', backgroundRepeat: 'repeat' }}>

                <Outlet />
                <Footer />
            </div>
        </div>

    )
}

export default Layout
