import React from 'react'
import RolesImage from '../assets/images/rolesImage.png'

const RolesAssignment = () => {
    return (
        <div className="w-[100%] h-[90vh] container gap-10 md:px-[40px] md:mt-0 mt-[100px] flex md:flex-row flex-col-reverse items-center">
            <div className='md:w-[50%] w-[90%]'>
                <img src={RolesImage} alt="RolesImage" />
            </div>


            <div className='md:w-[40%] w-[100%] p-[20px]  md:pe-[20px] md:ms-[100px]'>
                <p className='m-0 font-semibold text-[#DEA209]'>Efficiency</p>
                <p className='mb-[24px] leading-[55px] font-semibold text-[40px]' >Roles Assignment <br />
                    Flexible Role Assignments</p>
                <p className='m-0 text-[20px]'>{"Assign different roles to users such as Lead Generator, Executive, and Manager to streamline workflows."}</p>
            </div>
        </div>
    )
}

export default RolesAssignment
