import React from 'react'
import FaqComponent from '../components/faq-component'

import { data } from '../constant/faq-datas'



const FrequentlyAskedQuestion = () => {


    return (
        <div className='container  md:mt-[100px]' id='faq'>
            <div className='md:w-[70%] mx-auto'>

                <p className="text-[40px] text-center font-bold">
                    Frequently asked <span className='text-[#1D4ED8]'>questions</span>
                </p>
                <p className='text-[14px] text-[#888888] text-center'>
                    {"Have questions about our CRM solution? We've got you covered. Explore our frequently asked questions to find answers to common queries and gain a deeper understanding of how our platform can help streamline your business operations and drive growth."}
                </p>


            </div>
            <div>
                <FaqComponent data={data} />

            </div>

        </div>
    )
}

export default FrequentlyAskedQuestion
