import React from 'react'
import KanbanImage from '../assets/images/kanbanImage.png'

const KanbanBoard = () => {
    return (
        <div className="w-[100%] container h-[100vh] gap-10 md:px-[40px] flex md:flex-row flex-col-reverse items-center">
            <div className='md:w-[50%] w-[90%]'>
                <img src={KanbanImage} alt="RolesImage" />
            </div>


            <div className='md:w-[40%] w-[100%] p-[20px] md:pe-[10px] md:ms-[100px]'>
                <p className='m-0 font-semibold text-[#047AF8]'>Efficiency</p>
                <p className='mb-[24px] leading-[55px] font-semibold text-[40px]' >Kanban Board
                    Visualize Your Workflow</p>
                <p className='m-0 text-[20px]'>Use our Kanban board to visually manage your leads and tasks for improved organization and productivity.</p>
            </div>
        </div>
    )
}

export default KanbanBoard
