
import React, { useEffect, useState } from 'react'
import Button from "../../components/Button"
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom';
import CenterPopup from '../../components/center-popup';
import ThanksPopup from '../../components/thank-you-popup';
import { useLocation } from 'react-router-dom';



const Header = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [bgColor, setBgColor] = useState(false);




    const handleScroll = () => {
        if (window.scrollY > 50) {
            setBgColor(true);
        } else {
            setBgColor(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToSection = (id) => {
        if (location.pathname === '/') {
            document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
        } else {
            navigate('/');
            setTimeout(() => {
                document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
            }, 500);
        }
    };


    const btnName = "Contact us"

    return (
        <div className={`md:w-[98.9vw] sticky ${bgColor === true ? "bg-white/90 backdrop-blur-sm md:border-b" : "bg-transparent"} top-0 z-50 `}>

            <div className='relative  w-[100%] z-20  '>

                <div className='container  border-b-2 md:border-none'>

                    <Navbar expand="lg" className="">

                        <Navbar.Brand href="/">
                            <img className='' width={"153px"} height={"40px"} src={logo} alt="logo" />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className='navbar-mobile'>
                            <Nav className="mx-auto  d-flex justify-content-center">
                                <Nav.Link className={`mx-3 text-black navbar-content`} onClick={() => handleScrollToSection("solution")} href="#solution">Solution</Nav.Link>
                                <Nav.Link className={`mx-3 text-black navbar-content`} onClick={() => handleScrollToSection("pricing")} href="#pricing">Pricing</Nav.Link>
                                <Nav.Link className={`mx-3 text-black navbar-content`} onClick={() => handleScrollToSection("faq")} href="#faq">FAQs</Nav.Link>
                                <Nav.Link className={`mx-3 text-black navbar-content`} href="/partner-with-us">Partner with us</Nav.Link>
                                <Nav.Link className={`mx-3 text-black navbar-content`} href="">Blogs</Nav.Link>
                            </Nav>
                            <Nav className='d-flex align-items-center'>
                                <Nav.Link eventKey={1} href="" onClick={() => setShow(!show)}>
                                    <Button btnName={btnName} btnStyle=" text-[14px]  bg-white border border-[#E2E3E5] text-black rounded-[8px] px-4 py-2" />
                                </Nav.Link>
                            </Nav>

                        </Navbar.Collapse>

                    </Navbar>



                </div>
            </div>

            <div>
                <CenterPopup show={show} setShow={setShow} />
            </div>



        </div>
    )
}

export default Header
