import React from 'react'
import ImportImage from '../assets/images/importImage.png'
const ImportExport = () => {
    return (
        <div className='mt-[100px]'>
            <div className='text-center font-semibold text-[32px] mb-[100px] text-[#047AF8]'>
                <p className='mb-0'>Our Features</p>
            </div>

            <div className='w-[100%] absolute z-10 py-[50px]'>
                <p className='m-0 md:w-[100%] hidden absolute top-[-115px] md:block md:text-[130px] font-bold text-gray-100 text-center' >Why We're Awesome</p>

                <div className='flex md:flex-row flex-col items-center container'>

                    <div className='flex flex-col md:p-[100px] w-[100%] md:w-[50%]'>
                        <p className='m-0 text-[#DEA209] font-semibold'>Efficiency</p>
                        <p className='mb-[24px] text-[40px] font-semibold' >Import & Export</p>
                        <p className='m-0 text-[20px] text-[#354667]' >{"Quickly import leads and organizations with hassle-free integration. Easily filter and export data to meet your needs"}</p>
                    </div>

                    <div className='md:w-[50%] w-[100%]'>
                        <img src={ImportImage} alt="import_img" />
                    </div>
                </div>
            </div>

            <div className='bg-sky-100  opacity-30 h-[90vh] md:h-[85vh]  '>

            </div>
        </div>
    )
}

export default ImportExport
