import React from 'react'
import TrackingImage from '../assets/images/trackingImage.png'
const TrackingUpdates = () => {
    return (
        <div className=''>


            <div className='w-[100%]  absolute z-10 py-[50px]'>

                <div className='flex md:flex-row gap-10  flex-col-reverse items-center container'>



                    <div className='md:w-[50%] w-[100%]'>
                        <img src={TrackingImage} alt="import_img" />
                    </div>

                    <div className='flex flex-col w-[100%] md:pe-[80px] md:w-[50%]'>
                        <p className='m-0 text-[#047AF8] font-semibold'>Efficiency</p>
                        <p className='mb-[24px] text-[40px] font-semibold' >{"All Tracking Updates Comprehensive Call Tracking"}</p>
                        <p className='m-0 text-[20px] text-[#354667]' >{"Monitor and log call activities with detailed updates to ensure thorough lead engagement."}</p>
                    </div>
                </div>
            </div>

            <div className='bg-red-100 w-[100%] opacity-40 h-[110vh] md:h-[85vh]  '>

            </div>
        </div>
    )
}

export default TrackingUpdates
