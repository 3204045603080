import React, { useState } from 'react'
import { useFormik } from 'formik'
import BlobImage from '../../assets/images/Blob.png'
import * as Yup from 'yup'
import Button from '../../components/Button'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { fetchPostAffiliate } from "../../redux/slices/post-affiliate.slice"
import ThanksPopup from '../../components/thank-you-popup'
import metaIcon from '../../assets/images/metaIcon.png'

const Form = () => {


    const [showThanksPopup, setShowThanksPopup] = useState(false)

    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            company: '',
            Referral: '',
        },

        validationSchema: Yup.object().shape({
            name: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            phone: Yup.string()
                .matches(/^\d+$/, "Phone number must be numeric")
                .required('Phone number is required'),
            Referral: Yup.string().required('Required'),
        }),

        onSubmit: values => {
            // dispatch(fetchPostAffiliate(values)).then(
            //     formik.resetForm(),
            //     setShowThanksPopup(true)
            // )
        },
    });



    const numberValidate = (event) => {
        const { name, value } = event.target;
        const numericValue = value.replace(/\D/g, '').slice(0, 20);
        formik.setFieldValue(name, numericValue);
    }

    return (
        <div className='container mt-[80px]' >

            <div className='md:w-[50%] mx-[auto]' style={{ backgroundImage: `url(${BlobImage})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}>

                <p className="md:mb-[46px] text-[#0F337C] text-center text-[40px] font-bold">Affiliate Program </p>



                <form className='w-[100%] flex gap-[20px]  flex-col items-center' action="" onSubmit={formik.handleSubmit}>

                    <div className='w-[100%]'>
                        <p className='font-medium m-0'>Name</p>
                        <input className={`mt-1 px-2 h-[50px] ${formik.touched.name && formik.errors.name ? 'border-red-500 border-1 ' : 'border'} w-[100%] rounded`} type="text" name='name' onChange={formik.handleChange} value={formik.values.name} />
                    </div>
                    <div className='w-[100%]'>
                        <p className='font-medium m-0'>Email</p>
                        <input className={`px-2 h-[50px] mt-1 ${formik.touched.email && formik.errors.email ? 'border-red-500 border-1 ' : 'border'} w-[100%] rounded`} type="email" name='email' onChange={formik.handleChange} value={formik.values.email} />
                    </div>
                    <div className='w-[100%]'>
                        <p className='font-medium m-0'>Phone No</p>
                        <input onBluer={formik.handleBlur} className={`px-2 h-[50px] mt-1 ${formik.touched.phone && formik.errors.phone ? 'border-red-500 border-1 ' : 'border'} w-[100%]  rounded`} type="text" name='phone' onChange={(e) =>
                            numberValidate(e)
                        } value={formik.values.phone} />
                    </div>
                    <div className='w-[100%]'>
                        <p className='font-medium m-0'>Company Name <span className='text-[#444444]'>(if applicable)</span></p>
                        <input className=' px-2 h-[50px] border mt-1 w-[100%] rounded' type="text" name='company' onChange={formik.handleChange} value={formik.values.company} />
                    </div>

                    <div className='w-[100%]'>
                        <p className='font-medium m-0'>Referral Source</p>
                        <select className={`${formik.touched.Referral && formik.errors.Referral ? 'border-red-500 border-1 ' : 'border'} px-2 h-[50px] mt-1 w-[100%] rounded`} type="text" onChange={formik.handleChange} value={formik.values.Referral} name="Referral" id="">
                            <option className='text-gray-400' value="">Select</option>
                            <option value="Google">Google</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Linkedin">Linkedin</option>
                            <option value="Other">Other</option>
                        </select>

                    </div>

                    <button type='submit' className='mt-[20px]' >
                        <Button btnName='Submit' btnStyle='bg-[#0F337C] text-[white] px-[150px] py-[10px] rounded' />
                    </button>



                </form>

            </div>

            <ThanksPopup showThanksPopup={showThanksPopup} setShowThanksPopup={setShowThanksPopup}>
                <div className='bg-white flex items-center flex-col py-[30px] '>

                    <img src={metaIcon} alt="..." />

                    <p className='text-purple-700 m-0 font-bold text-[80px]'> Thank you!</p>
                    <div className="md:w-[20%] my-3 h-[6px] bg-orange-500 rounded-full"></div>

                    <p className='text-purple-700  mb-0 text-[20px] font-medium ' >Your request has been submitted</p>
                    <p className='text-purple-700 mb-0 text-[20px] font-medium ' >You will receive an email for confirmation.</p>



                    <div className="mt-[30px]" onClick={() => setShowThanksPopup(false)}>
                        <Button btnName={"Close"} btnStyle="cursor-pointer bg-orange-500 py-[10px] px-[38px] rounded-[8px] text-[18px] text-white" />
                    </div>

                </div>
            </ThanksPopup>

        </div >
    )
}

export default Form
