import React, { useState } from 'react'
import DemoBackgrounImage from '../assets/images/demoBackgroundImage.png'
import Button from '../components/Button'

import CenterPopup from '../components/center-popup'

const DemoBanner = () => {

    const [show, setShow] = useState(false)




    return (

        <div className='w-[100%] mt-[100px] h-[50vh] flex justify-center items-center' style={{ backgroundImage: `url(${DemoBackgrounImage})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}>

            <CenterPopup show={show} setShow={setShow} />
            <div className='flex items-center flex-col'>
                <p className='m-0 text-white text-[30px] md:text-[42px] leading-10 font-semibold'>Not Ready Yet?</p>
                <p className='m-0 text-white md:text-[42px] text-[25px] font-semibold text-center  '>Schedule a Demo from us</p>
                <div onClick={() => setShow(!show)}>
                    <Button btnName="Contact us" btnStyle="cursor-pointer bg-white py-[10px] px-[28px] mt-[20px] rounded-[8px] text-[14px] text-[#0F337C]" />
                </div>
            </div>
        </div>
    )
}

export default DemoBanner
